import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

interface Props {
  setUserCallback: (data: { name: string; association: string }) => void;
  existingUser: { name: string; association: string } | undefined;
}

export const Survey = ({ setUserCallback, existingUser }: Props) => {
  const handleSetUser = (data: object) => {
    setUserCallback(user);
  };

  const [user, setUser] = useState({ name: "", association: "" });

  useEffect(() => {
    if (existingUser) {
      setUser(existingUser);
    }
  }, [existingUser]);

  return (
    <div className="">
      <h1 className="pb-4">Welcome - About You</h1>
      <p className="pb-6">Please provide us with your name and association.</p>
      <form>
        <label className="block pb-6">
          <span className="text-gray-700 font-semibold">Full name:</span>
          <input
            type="email"
            className="form-input mt-1 block w-full"
            placeholder="john@example.com"
            value={user.name}
            onChange={(e) => {
              setUser({ ...user, name: e.target.value });
            }}
          />
        </label>
        <label className="block pb-10">
          <span className="text-gray-700 font-semibold">Association:</span>
          <input
            type="email"
            className="form-input mt-1 block w-full"
            placeholder="My Organization"
            value={user.association}
            onChange={(e) => {
              setUser({ ...user, association: e.target.value });
            }}
          />
        </label>
        <Link to="/survey/1">
          <button
            className="bg-brand-red hover:bg-brand-red-dark text-white py-2 px-4 focus:outline-none rounded mb-2"
            onClick={handleSetUser}
          >
            Continue
          </button>
        </Link>
      </form>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { SurveyData } from "../../data/questions";
import { Question, SectionInfo } from "../../components/";

interface Props {
  user: { name: string; association: string };
  answers: any[];
  editing?: boolean;
  setAnswerCallback: (data: {
    id: string;
    score: number;
    comment: string;
    sectionId: string | undefined;
    subSectionId: string | undefined;
  }) => void;
}

export const SurveyQuestion = ({
  user,
  answers,
  editing,
  setAnswerCallback,
}: Props) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [percentComplete, setPercentComplete] = useState(0);
  const [existingAnswer, setExistingAnswer] = useState<
    { id: string; score: number; comment: string } | undefined
  >(undefined);

  useEffect(() => {
    // Check if answer for question exists in answers array
    const existingAnswer = answers.find(
      (x) => x.id === SurveyData.questions[currentQuestion - 1].id
    );

    setExistingAnswer(existingAnswer);

    setPercentComplete(
      Math.round((currentQuestion / SurveyData.questions.length) * 100)
    );
  }, [currentQuestion, answers]);

  if (isNaN(parseInt(id)) || parseInt(id) > SurveyData.questions.length) {
    history.push("/survey/1");
  } else if (currentQuestion !== parseInt(id)) {
    setCurrentQuestion(parseInt(id));
  }

  const handleNext = (data: object) => {
    if (currentQuestion >= SurveyData.questions.length) {
      history.push("/summary");
    } else {
      history.push(`/survey/${currentQuestion + 1}`);
    }
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrev = () => {
    if (currentQuestion <= 1) {
      history.push("/survey");
    } else {
      history.push(`/survey/${currentQuestion - 1}`);
    }
    setCurrentQuestion(currentQuestion - 1);
  };

  const handleSetAnswer = (data: {
    id: string;
    score: number;
    comment: string;
    sectionId: string | undefined;
    subSectionId: string | undefined;
  }) => {
    setAnswerCallback(data);
  };

  if (SurveyData.questions[currentQuestion - 1].sectionInfo === true) {
    const sectionInfo = {
      title: SurveyData.questions[currentQuestion - 1].sectionHeader,
      description: SurveyData.questions[currentQuestion - 1].description,
    };
    return (
      <div>
        <SectionInfo
          sectionInfo={sectionInfo}
          nextCallback={handleNext}
          prevCallback={handlePrev}
        />
        <div className="mt-16 w-5/6 m-auto">
          <div className="shadow w-full bg-gray-200 mt-2">
            <div
              className="bg-brand-red text-xs leading-none text-center text-white"
              style={{ width: `${percentComplete}%`, height: "4px" }}
            ></div>
          </div>

          <p className="text-center pt-2">
            {currentQuestion} / {SurveyData.questions.length}{" "}
          </p>

          <p className="text-center pt-2">
            {user.name} - {user.association}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <Question
          id={SurveyData.questions[currentQuestion - 1].id}
          title={SurveyData.questions[currentQuestion - 1].title}
          sectionId={SurveyData.questions[currentQuestion - 1].sectionId}
          subSectionId={SurveyData.questions[currentQuestion - 1].subSectionId}
          existingAnswer={existingAnswer}
          sectionHeader={
            SurveyData.questions[currentQuestion - 1].sectionHeader
          }
          nextCallback={handleNext}
          prevCallback={handlePrev}
          setAnswerCallback={handleSetAnswer}
          editing={editing}
        />

        <div className="mt-16 w-5/6 m-auto">
          <div className="shadow w-full bg-gray-200 mt-2">
            <div
              className="bg-brand-red text-xs leading-none text-center text-white"
              style={{ width: `${percentComplete}%`, height: "4px" }}
            ></div>
          </div>

          <p className="text-center pt-2">
            {currentQuestion} / {SurveyData.questions.length}{" "}
          </p>

          <p className="text-center pt-2">
            {user.name} - {user.association}
          </p>
        </div>
      </div>
    );
  }
};

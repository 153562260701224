export const Sections = [
  {
    title: "1. Governance & Strategic Focus",
    overview:
      "Governance can be defined as the way an organization formulates its structures and implements policies guiding the conduct of its mission and the attainment of its goals. A well governed organization is an effective organization. Basic principles of effective governance include an accountable governing body, acting as the highest authority, and leading the organization towards achieving its mission; and a clearly sense of purpose as stated in its vision, mission, goals and strategic plan.",
    subSections: [
      {
        title: "1.1 Governing Body",
        overview:
          "Governing Body means the organizational structure that has ultimate authority and accountability for the association. This may be called a Board of Directors, Executive Counsel, Executive Committee, Governing Body, or some other name.",
        questions: [
          {
            id: "question-1",
            title: "1.1.1",
            position: 3,
            overview:
              "The governing body performs its role to provide overall policy direction and oversight for the association and directing the activities of the association. The governing body also provides financial accountability and financial oversight of the association.",
          },
          {
            id: "question-2",
            title: "1.1.2",
            position: 4,
            overview:
              "The governing body performs its role to provide overall policy direction and oversight for the association and directing the activities of the association. The governing body also provides financial accountability and financial oversight of the association.",
          },
          {
            id: "question-3",
            title: "1.1.3",
            position: 5,
            overview:
              "The governing body fulfills its fundraising role (e.g. assists in resource mobilization for the activities of the association) and its lobbying role (e.g. promotes the activities of the association and advocates for strengthened biosafety to stakeholders).",
          },
          {
            id: "question-4",
            title: "1.1.4",
            position: 6,
            overview:
              "The governing body is composed of committed leaders who represent the varied interests of the members and stakeholders. These leaders have the skills, knowledge and experience to carry out their roles effectively.",
          },
          {
            id: "question-5",
            title: "1.1.5",
            position: 7,
            overview:
              "At least 70% of the governing body members attend all association and governing body meetings. All records of decisions made by the governing body are documented and retained by the association.",
          },
        ],
      },
      {
        title: "1.2 Mission & Goals",
        overview: "",
        questions: [
          {
            id: "question-6",
            title: "1.2.1",
            position: 8,
            overview:
              "The association has a clearly articulated vision, mission statement and goals. The mission and goals are communicated to and understood by the members and stakeholders.",
          },
          {
            id: "question-7",
            title: "1.2.2",
            position: 9,
            overview:
              "The association has the necessary sectoral expertise (e.g. biosafety, biosecurity, biorisk management, biocontainment) to accomplish its mission.",
          },
          {
            id: "question-8",
            title: "1.2.3",
            position: 10,
            overview:
              "The association has a written strategic plan which is realistically achievable and aligned with its mission and goals. The strategic plan includes clearly articulated SMART objectives (specific, measurable, achievable, results-focused, time-bound) and is developed by the governing body with input from the members and stakeholders.",
          },
          {
            id: "question-9",
            title: "1.2.4",
            position: 11,
            overview:
              "The development of the mission, goals and objectives, and the strategic planning process, is based on analysis of the external environment (e.g. political context, meeting the needs of external stakeholders, economical factors, relevance, impact) and the internal environment (e.g. meeting the needs of membership, association’s governance structure & strategic leadership, internal human and financial resources). Programs are regularly reviewed and adapted to the changing needs of its members and stakeholders.",
          },
          {
            id: "question-10",
            title: "1.2.5",
            position: 12,
            overview:
              "The association uses performance indicators to measure progress towards the achievement of its mission and the impact of its activities. The results of these evaluations are used to make adjustments to the association’s activities. Activities are also adapted based on the changing needs of its members and stakeholders.",
          },
        ],
      },
    ],
  },
  {
    title: "2. Management & Administration",
    overview:
      "The management and administration of an association’s day-to-day operations greatly influence the ability of the association to carry out its mission and goals. A well-managed association has an operational structure with clearly defined roles and responsibilities (e.g. management, secretariat, staff, committees, volunteers); develops and implements work plans and activities reflecting a broader strategic plan; plans for and allocates resources properly; adheres to clearly defined administrative procedures; regularly reports on activities to members and stakeholders; and, places value on human resources with formalized personnel practices.",
    subSections: [
      {
        title: "2.1 Operational Structure & Administration",
        overview: "",
        questions: [
          {
            id: "question-11",
            title: "2.1.1",
            position: 14,
            overview:
              "The association has a defined operational structure to carry out the organization’s day-to-day activities (e.g. management, secretariat, staff, volunteers, committees) with clear lines of authority and responsibility. The structure is defined within the association’s bylaws and policies.",
          },
          {
            id: "question-12",
            title: "2.1.2",
            position: 15,
            overview:
              "The association’s operational structure is realistic and provides an adequate framework for carrying out the mission and goals of the organisation.",
          },
          {
            id: "question-13",
            title: "2.1.3",
            position: 16,
            overview:
              "Each of the positions within the operational structure are filled (i.e. either by staff or volunteers). Individuals are drawn from a diverse background, are free from conflict of interest, bring to the association a wide range of skills and experience, and are capable of collaborating across the entire organization.",
          },
          {
            id: "question-14",
            title: "2.1.4",
            position: 17,
            overview:
              "Written administrative procedures (e.g. day-to-day operations, human resources, document control, travel, financial management) have been developed and are adhered to. These procedures are regularly reviewed and updated as required.",
          },
          {
            id: "question-15",
            title: "2.1.5",
            position: 18,
            overview:
              "Administrative procedures include risk management activities to protect the association (e.g. external audits of the association’s operations, inventory-taking).",
          },
        ],
      },
      {
        title: "2.2 Operational Planning & Reporting",
        overview: "",
        questions: [
          {
            id: "question-16",
            title: "2.2.1",
            position: 19,
            overview:
              "The association’s operational work plans reflect the strategic plan and include specific activities, accountabilities, budgets, time frames for implementation, monitoring and evaluation.",
          },
          {
            id: "question-17",
            title: "2.2.2",
            position: 20,
            overview:
              "Work plans and activities are jointly developed by the appropriate members and stakeholders.",
          },
          {
            id: "question-18",
            title: "2.2.3",
            position: 21,
            overview:
              "Resources (human and financial) to implement the association’s work plans are planned for and allocated properly.",
          },
          {
            id: "question-19",
            title: "2.2.4",
            position: 22,
            overview:
              "Work plans are reviewed regularly and updated when necessary. Flexibility exists to adjust work plans and activities as a result of the monitoring process.",
          },
          {
            id: "question-20",
            title: "2.2.5",
            position: 23,
            overview:
              "The association produces quality activity and evaluation reports which are distributed to its members and stakeholders.",
          },
        ],
      },
      {
        title: "2.3 Managing Human  Resources",
        overview: "",
        questions: [
          {
            id: "question-21",
            title: "2.3.1",
            position: 24,
            overview:
              "Written procedures and criteria are in place for the selection of staff, volunteers, committee members and other individuals within the association’s operational structure. The diversity of the association’s membership is reflected in these human resources.",
          },
          {
            id: "question-22",
            title: "2.3.2",
            position: 25,
            overview:
              "Individuals are provided with the tools and training necessary to carry out responsibilities based on the association’s capacity needs to carry out program objectives. Individuals with financial responsibilities have the skills and qualifications needed to oversee financial responsibilities for the association.",
          },
          {
            id: "question-23",
            title: "2.3.3",
            position: 26,
            overview:
              "Individuals participate in decisions on how their own work is organized and know what is expected of them. Teamwork is encouraged, communication is open, and information is shared freely among all individuals.",
          },
          {
            id: "question-24",
            title: "2.3.4",
            position: 27,
            overview:
              "The association provides incentives, either financial (e.g. competitive salaries, benefits) or non-financial (e.g. special projects, assignments, acquiring and using new skills, continual learning and career development), to maintain motivation and the full potential of its human resources.",
          },
          {
            id: "question-25",
            title: "2.3.5",
            position: 28,
            overview:
              "The performance of individuals is monitored, evaluated and feedback given. Performance recognition schemes, and grievances/conflict resolution procedures are in place.",
          },
        ],
      },
    ],
  },
  {
    title: "3. Financial Resources",
    overview:
      "The governing body has overall responsibility for financial stewardship and ensuring appropriate procedures and systems are in place for managing the association’s financial resources. Good financial management is not only concerned with the raising and allocation of funds to attain the association’s goals, but also addresses the way in which funds are used for the purpose intended. It not sufficient to be able to access funds – associations must spend their financial resources wisely on relevant activities that are aligned with their strategic goals and objectives. Unrestricted funds are also available to cover fundament core costs and overhead expenses. Equally important are accurate financial accounting procedures, transparency, auditing and reporting. The association’s accounting system and financial controls should be aligned with internationally accepted accounting standards and best practices. Finally, financially sustainable associations have a diversified income base including income generating activities and are able to mobilize and efficiently manage resources without excessive dependence on any single funding source or revenue generating stream.",
    subSections: [
      {
        title: "3.1 Generating Income & Fundraising",
        overview: "",
        questions: [
          {
            id: "question-26",
            title: "3.1.1",
            position: 30,
            overview:
              "The association has a diversified income without excessive dependence on any single funding source or revenue generating stream.",
          },
          {
            id: "question-27",
            title: "3.1.2",
            position: 31,
            overview:
              "The association generates its own income in addition to membership fees through fee-for-services or other cost recovery mechanisms.",
          },
          {
            id: "question-28",
            title: "3.1.3",
            position: 32,
            overview:
              "The association has a realistic fundraising strategy to access a variety of additional resources such as in-kind contributions and donations.",
          },
          {
            id: "question-29",
            title: "3.1.4",
            position: 33,
            overview:
              "The association has funds for short-term expenses (e.g. for next 3 months) and a long-term resource mobilization plan. There is a policy for financial reserves to enable the association to meet its mission in periods when funds are not forthcoming.",
          },
        ],
      },
      {
        title: "3.2 Accounting & Controls",
        overview: "",
        questions: [
          {
            id: "question-30",
            title: "3.2.1",
            position: 34,
            overview:
              "An individual with training in financial accounting/bookkeeping is responsible for the association’s accounting procedures. These procedures follows sound financial accounting practices using internationally accepted financial tools (delegation of authority, accounting software to process and record financial transactions, Chart of Accounts, reconciliation of bank statements, cash books, receipt books, invoices, fixed asset register). A supporting document is available for every income and expenditure transaction.",
          },
          {
            id: "question-31",
            title: "3.2.2",
            position: 35,
            overview:
              "Account categories (e.g. cost centers) exist for separating individual project funds and to avoid cross-project funding. The association is able to track core overhead and operational costs separately from project funds.",
          },
          {
            id: "question-32",
            title: "3.2.3",
            position: 36,
            overview:
              "Sound procurement processes are in place and followed. The association verifies that goods and services bought by the association have been received before invoices are paid. Salaries are paid after substantiation of the activities performed and hours accumulated (e.g. time-sheets).",
          },
          {
            id: "question-33",
            title: "3.2.4",
            position: 37,
            overview:
              "Internal financial controls are in place to provide assurances that assets are safeguarded, have been accurately presented in the financial statements and that local laws and regulations have been observed (e.g. petty cash locked in cash box or safe, bank accounts in the name of the association, transactions authorized, cheques not signed before the payee is written in and supporting documents verified, supporting documents and bank statements are kept on file.",
          },
          {
            id: "question-34",
            title: "3.2.5",
            position: 38,
            overview:
              "An annual external financial audit is conducted by a qualified independent individual. Auditors are not selected by individuals with financial responsibility within the association. Recommendations from the auditor (e.g. to correct any weaknesses in financial control, suggestions to improve financial management practices) are implemented.",
          },
        ],
      },
      {
        title: "3.3 Budgeting",
        overview: "",
        questions: [
          {
            id: "question-35",
            title: "3.3.1",
            position: 39,
            overview:
              "An individual(s) with experience in financial management is responsible for the preparation, management and implementation of the budget. Budgets are approved by the governing body of the association.",
          },
          {
            id: "question-36",
            title: "3.3.2",
            position: 40,
            overview:
              "The budgeting process is integrated into the development of annual work plans and activities. Budgets are prepared for the association as whole and for individual projects as may be required. Budgets include forecasts for income, expenditures and cash flow. To facilitate planning and identify in advance areas where risks may lay, budgets separately consider the flow of restricted funds (e.g. for specific projects) and unrestricted funds (e.g. for overhead expenses).",
          },
          {
            id: "question-37",
            title: "3.3.3",
            position: 41,
            overview:
              "The budget forecast includes enough income to pay for planned expenditures with flexibility for adjustments due to changes in circumstances. An approval process is in place for planned variations and changes to the budget.",
          },
          {
            id: "question-38",
            title: "3.3.4",
            position: 42,
            overview:
              "Actual expenses are regularly monitored against the budget and variances are investigated. The governing body receives regular budget monitoring reports showing actual versus budgeted expenditure with notes explaining the variances.",
          },
        ],
      },
      {
        title: "3.4 Financial Reporting",
        overview: "",
        questions: [
          {
            id: "question-39",
            title: "3.4.1",
            position: 43,
            overview:
              "Financial reports are presented to the governing body and include a balance sheet (i.e. summarizing assets, liabilities and net assets) and an income/expense statement (i.e. showing income minus expenses, resulting in either a surplus or deficit).",
          },
          {
            id: "question-40",
            title: "3.4.2",
            position: 44,
            overview:
              "Real-time statements of cash flow and functional expenses are prepared to facilitate short-term planning and anticipate any shortfalls.",
          },
          {
            id: "question-41",
            title: "3.4.3",
            position: 45,
            overview:
              "The association understands what other financial reports are required (e.g. financial reports to donors and funding agencies). Such reports are prepared in the proper format and submitted to the donor within the allocated reporting period.",
          },
          {
            id: "question-42",
            title: "3.4.4",
            position: 46,
            overview:
              "All financial reports are used by the association for review purposes and for long-term planning.",
          },
        ],
      },
    ],
  },
  {
    title: "4. Stakeholder Relations & Service Delivery",
    overview:
      "The association’s relationships with its members and stakeholders will determine whether or not the association’s mission and goals are realized. Members and stakeholders must be supportive of what the association is intent on accomplishing and the association-stakeholder relationship is one of partnership for a common purpose. A fully involved and motivated membership, stakeholders who see the association as having value and impact, and a positive image within the local community, will lead to a more sustainable organization. Finally, in order for the association to remain sustainable, it must adapt to a changing context and continue to be relevant to its members and stakeholders over time.",
    subSections: [
      {
        title: "4.1 Member Relations",
        overview: "",
        questions: [
          {
            id: "question-43",
            title: "4.1.1",
            position: 48,
            overview:
              "The organization’s membership is drawn from a variety of stakeholders (e.g. diagnostic & research laboratories, scientific institutions, governments, academia, private industry, non-governmental organizations).",
          },
          {
            id: "question-44",
            title: "4.1.2",
            position: 49,
            overview:
              "The association’s members are involved in the activities of the association, motivated and committed to success of the organization.",
          },
          {
            id: "question-45",
            title: "4.1.3",
            position: 50,
            overview:
              "The culture operating within the association’s membership, and between the membership and governing body, is participatory and consensus building working towards a common goal.",
          },
          {
            id: "question-46",
            title: "4.1.4",
            position: 51,
            overview:
              "There is effective and ongoing communication from the governing body to the members. There is a mechanism for members to communicate with the governing body and provide input into the organization’s goals and strategic plans.",
          },
          {
            id: "question-47",
            title: "4.1.5",
            position: 52,
            overview:
              "The members regularly attend the association’s meetings, conferences and events. If applicable, membership fees are paid by the members annually, or, as may be required by the association’s bylaws.",
          },
        ],
      },
      {
        title: "4.2 Stakeholder Relations",
        overview: "",
        questions: [
          {
            id: "question-48",
            title: "4.2.1",
            position: 53,
            overview:
              "The association has established relationships with a variety of stakeholders (e.g. governments, research institutes, laboratories, academia, private sector, NGOs, donors, funding agencies, international agencies) and is recognized as a credible and valuable resource.",
          },
          {
            id: "question-49",
            title: "4.2.2",
            position: 54,
            overview:
              "The biosafety expertise and services provided by the association to its stakeholders is highly relevant and beneficial. The association-stakeholder relationship is one of partnership for a common purpose.",
          },
          {
            id: "question-50",
            title: "4.2.3",
            position: 55,
            overview:
              "The association engages in dialogue with policy-makers and is called upon by governments to collaborate on biosafety issues.",
          },
          {
            id: "question-51",
            title: "4.2.4",
            position: 56,
            overview:
              "The association has a relationship with the private sector for technical expertise, materials, in-kind contributions, and/or human and financial resources.",
          },
          {
            id: "question-52",
            title: "4.2.5",
            position: 57,
            overview:
              "The association collaborates with and shares resources among other national, regional and international biosafety associations.",
          },
        ],
      },
      {
        title: "4.3 Public Relations & Media",
        overview: "",
        questions: [
          {
            id: "question-53",
            title: "4.3.1",
            position: 58,
            overview:
              "The association engages in public relations and has a positive image among the local community and public. The association’s mission and goals is understood and seen as having value.",
          },
          {
            id: "question-54",
            title: "4.3.2",
            position: 59,
            overview:
              "The association has a strategy for and regularly engages with the media. The association has received positive media attention. The media consults with the association on relevant issues.",
          },
          {
            id: "question-55",
            title: "4.3.3",
            position: 60,
            overview:
              "The association has a marketing plan to build brand awareness and position itself as a source of biosafety expertise.",
          },
        ],
      },
      {
        title: "4.4 Service Delivery & Benefit",
        overview: "",
        questions: [
          {
            id: "question-56",
            title: "4.4.1",
            position: 61,
            overview:
              "The association’s activities are supported by the members & stakeholders. They recognize the benefits of, and are involved in, the work of the association.",
          },
          {
            id: "question-57",
            title: "4.4.2",
            position: 62,
            overview:
              "The association is providing biosafety capacity building and skills-transfer to local stakeholders (e.g. training, technical assistance).",
          },
          {
            id: "question-58",
            title: "4.4.3",
            position: 63,
            overview:
              "The association plans and adapts its activities in accordance with changes in the local stakeholder environment (e.g. increased awareness of biosafety, strengthened biosafety practices).",
          },
        ],
      },
    ],
  },
];

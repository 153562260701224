import React from "react";
import { Link } from "react-router-dom";

import IFBALogo from "../../images/IFBA-logo.png";
import Diagram from "../../images/diagram-1.png";

export const Intro = () => {
  return (
    <React.Fragment>
      <img src={IFBALogo} className="logo" alt="IFBA Logo" />
      <h1 className="pb-4">IFBA Biosafety Association Sustainability Tool</h1>
      <p className="">
        This tool is being provided to IFBA Member Biosafety Associations for
        self-assessment of their organizational capacity and sustainability.
        Sustainability is a measure of an association’s ability to fulfill its
        mission, to serve its members and stakeholders over time, and to deliver
        lasting impactful outcomes. The assessment questions are compiled on the
        basis of the work of many organizations including{" "}
        <a
          href="https://www.pactworld.org/library/pact%E2%80%99s-organizational-capacity-assessment-facilitator%E2%80%99s-guide"
          target="_blank"
          rel="noreferrer"
        >
          PACT
        </a>{" "}
        (an international capacity development organization) and{" "}
        <a
          href="https://internationalbiosafety.org/wp-content/uploads/2020/12/KEPA-Organisational-Capacity-Assessment.pdf"
          target="_blank"
          rel="noreferrer"
        >
          KEPA’s “Organizational Capacity Assessment: An Introduction to a Tool”
        </a>
        (a Finish organization who provide capacity building assistance to
        non-governmental organizations).
      </p>
      <p className="">
        Results from the assessment will help each association deepen its
        understanding of their current capacity and includes ratings across a
        variety of capacity and sustainability elements:
      </p>
      <ul className="list-disc list-inside">
        <li>
          <span className="font-semibold">Governance & Strategic Focus</span> -
          the way an organization formulates and implements policies guiding the
          conduct of its mission and the attainment of its goals
        </li>
        <li>
          <span className="font-semibold">Management & Administration</span> -
          the organization’s structures, practices and human resources to manage
          and administer its activities and day-to-day operations
        </li>
        <li>
          <span className="font-semibold">Financial Resources</span> - the way
          in which funds are raised, managed and used for the purpose intended
        </li>
        <li>
          <span className="font-semibold">
            Stakeholder Relations & Service Delivery
          </span>
          - the way members and stakeholders interact with the association and
          benefit from its activities
        </li>
      </ul>
      <p>
        Each of the 4 assessment areas includes a number of sub-levels as
        illustrated below:
      </p>
      <div className="content-center">
        <img
          src={Diagram}
          alt="Diagram"
          style={{ maxWidth: "70%" }}
          className="mx-auto"
        />
      </div>
      <Link to="/survey">
        <button className="mt-6 bg-brand-red hover:bg-brand-red-dark text-white py-2 px-4 focus:outline-none rounded mb-2">
          Begin Assessment
        </button>
      </Link>
    </React.Fragment>
  );
};

import React from "react";
import DOMPurify from "dompurify";

interface Props {
  sectionInfo: { title: string; description: string };
  nextCallback: (data: object) => void;
  prevCallback: () => void;
}

export const SectionInfo = ({
  sectionInfo,
  nextCallback,
  prevCallback,
}: Props) => {
  const handleNext = () => {
    nextCallback({ rangeValue: 0, comment: "" });
  };
  const handlePrev = () => {
    prevCallback();
  };

  return (
    <div className="">
      <h1
        className="pb-4"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(sectionInfo.title),
        }}
      ></h1>
      <p
        className="pb-6"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(sectionInfo.description),
        }}
      ></p>
      <div className="border-b rounded-tl-lg rounded-tr-lg p-2 flex pb-6">
        <div className="flex-1">
          <button
            className="bg-brand-red hover:bg-brand-red-dark text-white py-2 px-4 focus:outline-none rounded mb-2 "
            onClick={handlePrev}
          >
            Previous
          </button>
        </div>
        <button
          className="bg-brand-red hover:bg-brand-red-dark text-white py-2 px-4 focus:outline-none rounded mb-2 float-right"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

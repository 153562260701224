import DOMPurify from "dompurify";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// @ts-ignore
import html2pdf from "html2pdf.js";

import { Sections } from "../../data/sections";
import IFBALogo from "../../images/IFBA-logo.png";

interface Props {
  answers: any;
  user: { name: string; association: string };
}

export const Summary = ({ answers, user }: Props) => {
  const history = useHistory();
  const [isRunning, setIsRunning] = useState(false);

  // Merge sections and answers for output

  let mergedSectionsAnswers = Sections.map((section: any) => {
    let mainScore = 0;
    let numberOfSections = section.subSections.length;

    let sections = section.subSections.map((subSection: any) => {
      let questionTotalScores = 0;
      let numberOfQuestions = subSection.questions.length;

      let questions = subSection.questions.map((question: any) => {
        let originalQuestion: any = question;
        answers.forEach((answer: any) => {
          if (question.id === answer.id) {
            originalQuestion = Object.assign({}, originalQuestion, answer);
            questionTotalScores += answer.score;
          }
        });
        return { ...originalQuestion };
      });
      const sectionScore = Math.round(questionTotalScores / numberOfQuestions);
      mainScore += sectionScore;
      return { ...subSection, questions, sectionScore };
    });

    const mainSectionScore = Math.round(mainScore / numberOfSections);
    return { ...section, sections, mainSectionScore };
  });

  const generatePDF = async () => {
    var element = document.getElementById("capture2")!.innerHTML;
    var pageSize = { width: 8, height: 10, unit: "in", k: 72 };
    var opt = {
      enableLinks: true,
      filename: "IFBA-Assessment.pdf",
      image: { type: "jpeg", quality: 1 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // New Promise-based usage:
    await html2pdf()
      .set(opt)
      .setMargin(0.25)
      .setPageSize(pageSize)
      .from(element)
      .save();

    setIsRunning(false);
  };

  const renderPageBreak = () => {
    return <div style={{ display: "block", pageBreakAfter: "always" }}></div>;
  };

  const summary = mergedSectionsAnswers.map((section: any, i: number) => {
    return (
      <div
        className=""
        key={"summary" + i}
        style={{ marginTop: "25px", marginBottom: "20px" }}
      >
        <label>
          <table className="table-fixed min-w-full divide-y divide-gray-100">
            <tbody className="">
              <tr className="transition-all">
                <td className="w-3/6">
                  <div
                    className="flex items-center"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        `<p class='font-semibold text-l'>${section.title}</p>`
                      ),
                    }}
                  ></div>
                </td>
                <td className=" whitespace-nowrap w-3/6">
                  <div className="text-sm text-gray-900">
                    <strong>{section.mainSectionScore}%</strong>
                    <div className="shadow w-full bg-gray-200 mt-2">
                      <div
                        className="bg-brand-red text-xs leading-none text-center text-white"
                        style={{
                          width: `${section.mainSectionScore}%`,
                          height: "4px",
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </label>
      </div>
    );
  });

  const renderPDFSummary = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <strong style={{ fontSize: "18px" }}>Score Summary</strong>
        {summary}
      </div>
    );
  };

  const tabsPdf = mergedSectionsAnswers.map((section: any, i: number) => {
    return (
      <React.Fragment>
        <div style={{ pageBreakInside: "avoid" }}>
          <table
            className="table-fixed min-w-full overflow-x-scroll divide-y divide-gray-200"
            style={{ pageBreakInside: "avoid", marginBottom: "20px" }}
          >
            <tbody className="">
              <tr className="transition-all">
                <td className="">
                  <div
                    className="flex items-center"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        `<p class='text-xl font-semibold' style='min-width:400px'>${section.title}</p>`
                      ),
                    }}
                  ></div>
                </td>
                <td className="" width="60">
                  <div className="text-sm text-gray-900">
                    <strong style={{ float: "right", fontSize: "20px" }}>
                      {section.mainSectionScore}%
                    </strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{section.overview}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          {section.sections.map((subSection: any) => {
            return (
              <div style={{ pageBreakInside: "avoid" }}>
                <table
                  className="table-fixed min-w-full overflow-x-scroll divide-y divide-gray-200"
                  style={{ marginBottom: "10px" }}
                >
                  <tbody className="">
                    <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
                      <td className="">
                        <div
                          className="flex items-center"
                          style={{ width: "400px", marginLeft: "15px" }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              `<p class='text-xl'>${subSection.title}</p>`
                            ),
                          }}
                        ></div>
                      </td>
                      <td width="60">
                        <div className="text-sm text-gray-900">
                          <strong style={{ float: "right", fontSize: "20px" }}>
                            {subSection.sectionScore}%
                          </strong>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style={{ marginLeft: "15px" }}>
                          {subSection.overview}
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <table className="table-fixed min-w-full divide-y divide-gray-200">
                  <tbody className="bg-white divide-y divide-gray-200">
                    {subSection.questions.map((question: any) => {
                      return (
                        <tr>
                          <td className="px-2 py-2 lg:px-5 lg:py-4">
                            <div
                              className="flex items-top text-sm lg:text-base"
                              style={{
                                marginLeft: "20px",
                                marginBottom: "12px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  `<p style='font-size: 16px; line-height: 16px'><strong>${question.title}:</strong> ${question.overview}</p>`
                                ),
                              }}
                            ></div>
                            <div style={{ marginLeft: "20px" }}>
                              <p
                                className=""
                                style={{ fontSize: "14px", lineHeight: "14px" }}
                              >
                                <strong>Comment:</strong> {question.comment}
                              </p>
                            </div>
                          </td>
                          <td width="60">
                            <strong
                              style={{ float: "right", fontSize: "14px" }}
                            >
                              {question.score}%
                            </strong>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
        {renderPageBreak()}
      </React.Fragment>
    );
  });

  const renderStaticIntroContent = () => {
    return (
      <React.Fragment>
        <img src={IFBALogo} className="logo" alt="IFBA Logo" />
        <p
          style={{
            fontSize: "28px",
            borderBottom: "1px solid #c0c0c0",
            padding: "10px 0 7px",
            marginBottom: "10px",
            fontFamily: "Arial",
          }}
        >
          Summary of Results
          <br /> Biosafety Association Sustainability Assessment
        </p>
        <p style={{ marginBottom: "10px", fontFamily: "Arial" }}>
          Prepared for: {user.name} - {user.association}
        </p>
        <div style={{ display: "block", pageBreakAfter: "always" }}></div>
        <h3 className="font-semibold" style={{ fontSize: "18px" }}>
          Interpreting the Results
        </h3>
        <p style={{ fontSize: "17px", lineHeight: "18px" }}>
          The results of this self-assessment tool should not be interpreted as
          pass or fail but rather as a mechanism to highlight which areas of the
          association have strengths and which are weaker. The results can also
          be used to define the stage of growth for the association as a whole.
          Five stages of growth for a non-governmental organization are commonly
          described as follows:
        </p>
        <table className="m-auto w-full overflow-x-scroll divide-y divide-gray-200 my-8">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                style={{ fontSize: "15px" }}
              >
                Stage
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                style={{ fontSize: "15px" }}
              >
                State
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                style={{ fontSize: "15px" }}
              >
                Characteristics
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
              <td
                className="px-6 py-2 whitespace-nowrap"
                style={{ fontSize: "15px" }}
              >
                1.
              </td>
              <td className="px-6 py-2" style={{ minWidth: "150px" }}>
                <span
                  className="inline-flex px-2 text-xs leading-5 text-green-900 bg-yellow-100 rounded-full"
                  style={{ fontSize: "12px" }}
                >
                  Embryonic
                </span>
              </td>
              <td
                className="px-6 py-2 text-base text-gray-500"
                style={{ fontSize: "15px", lineHeight: "16px" }}
              >
                In the initial stage a group of persons organise around a common
                purpose. The organisational structure is underdeveloped, and the
                activities are loosely structured around some ideas on what
                should be done and how resources could be mobilised. Direction
                is normally lacking and functions and roles unclear.
              </td>
            </tr>

            <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
              <td
                className="px-6 py-2 whitespace-nowrap"
                style={{ fontSize: "15px", lineHeight: "16px" }}
              >
                2.
              </td>
              <td className="px-6 py-2">
                <span
                  className="inline-flex px-2 text-xs leading-5 text-green-900 bg-green-100 rounded-full"
                  style={{ fontSize: "12px", lineHeight: "16px" }}
                >
                  Emerging
                </span>
              </td>
              <td
                className="px-6 py-2 text-base text-gray-500"
                style={{ fontSize: "15px", lineHeight: "16px" }}
              >
                The organisation still operates with volunteers but is beginning
                to put organizational systems in place. The organisation plans
                activities, outlines roles and starts following a certain
                direction.
              </td>
            </tr>

            <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
              <td
                className="px-6 py-2 whitespace-nowrap"
                style={{ fontSize: "15px", lineHeight: "16px" }}
              >
                3.
              </td>
              <td className="px-6 py-2">
                <span
                  className="inline-flex px-2 text-xs leading-5 text-green-900 bg-blue-100 rounded-full"
                  style={{ fontSize: "12px", lineHeight: "16px" }}
                >
                  Growing
                </span>
              </td>
              <td
                className="px-6 py-2 text-base text-gray-500"
                style={{ fontSize: "15px", lineHeight: "16px" }}
              >
                The organization starts rethinking service delivery in terms of
                effectiveness and efficiency, putting more sophisticated
                operational planning tools in place and attempting to attract
                donors on the basis of achievements rather than intentions. The
                organisation is more aware of its stakeholders and the
                possibility for them to influence the organisation’s policy
                direction and decision-making.
              </td>
            </tr>

            <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
              <td
                className="px-6 py-2 whitespace-nowrap"
                style={{ fontSize: "15px", lineHeight: "16px" }}
              >
                4.
              </td>
              <td className="px-6 py-2">
                <span
                  className="inline-flex px-2 text-xs leading-5 text-green-900 bg-indigo-300 rounded-full"
                  style={{ fontSize: "12px", lineHeight: "16px" }}
                >
                  Well-Developed
                </span>
              </td>
              <td
                className="px-6 py-2 text-base text-gray-500"
                style={{ fontSize: "15px", lineHeight: "16px" }}
              >
                The organization is responsive to stakeholders, activities are
                efficiently carried out and decisions are made strategically.
                The organisation is a reputable agent with stable finances
                consisting of a mix of long-term sponsors and internal resource
                mobilisation endeavours. Human resource management is oriented
                to motivating and supervising working teams of experts.
              </td>
            </tr>

            <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
              <td
                className="px-6 py-2 whitespace-nowrap"
                style={{ fontSize: "15px", lineHeight: "16px" }}
              >
                5.
              </td>
              <td className="px-6 py-2">
                <span
                  className="inline-flex px-2 text-xs leading-5 text-green-900 bg-purple-400 rounded-full"
                  style={{ fontSize: "12px", lineHeight: "16px" }}
                >
                  Mature
                </span>
              </td>
              <td
                className="px-6 py-2 text-base text-gray-500"
                style={{ fontSize: "15px", lineHeight: "16px" }}
              >
                A self-sustained organisation that can be reasonably expected to
                survive on its own.
              </td>
            </tr>
          </tbody>
        </table>
        <p className="pb-8" style={{ fontSize: "17px", lineHeight: "18px" }}>
          Further information on the stages of organization growth and
          sustainability can be found in KEPA's{" "}
          <a href="https://internationalbiosafety.org/wp-content/uploads/2020/12/KEPA-Organisational-Capacity-Assessment.pdf">
            KEPA’s “Organizational Capacity Assessment: An Introduction to a
            Tool”
          </a>
          .
        </p>
      </React.Fragment>
    );
  };

  const pdfFriendly = (
    <div style={{}}>
      <div
        id="capture2"
        style={{
          display: "none",
          position: "absolute",
          top: 0,
          left: 0,
          width: "1200px",
        }}
      >
        {renderStaticIntroContent()}
        {renderPageBreak()}
        {renderPDFSummary()}
        {renderPageBreak()}

        {tabsPdf}
      </div>
    </div>
  );

  const tabs = mergedSectionsAnswers.map((section: any, i: number) => {
    return (
      <div className="tab" key={i}>
        <input type="checkbox" id={`chck${i}`} className="summary" />
        <label className="tab-label" htmlFor={`chck${i}`}>
          <table className="table-fixed min-w-full overflow-x-scroll divide-y divide-gray-200">
            <tbody className="">
              <tr className="transition-all">
                <td className="w-3/6">
                  <div
                    className="flex items-center"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        `<p class='font-semibold text-xl'>${section.title}</p>`
                      ),
                    }}
                  ></div>
                </td>
                <td className=" whitespace-nowrap w-3/6">
                  <div className="text-sm text-gray-900">
                    <strong>{section.mainSectionScore}%</strong>
                    <div className="shadow w-full bg-gray-200 mt-2">
                      <div
                        className="bg-brand-red text-xs leading-none text-center text-white"
                        style={{
                          width: `${section.mainSectionScore}%`,
                          height: "4px",
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </label>
        <div className="tab-content">
          {section.sections.map((subSection: any, y: number) => {
            return (
              <React.Fragment>
                <table
                  className="table-fixed min-w-full overflow-x-scroll divide-y divide-gray-200"
                  key={i + "-" + y}
                >
                  <tbody className="">
                    <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
                      <td className="w-3/6 py-4">
                        <div
                          className="flex items-center"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              `<p class='font-semibold text-xl'>${subSection.title}</p>`
                            ),
                          }}
                        ></div>
                      </td>
                      <td className=" whitespace-nowrap w-3/6 py-4">
                        <div className="text-sm text-gray-900">
                          <strong>{subSection.sectionScore}%</strong>
                          <div className="shadow w-full bg-gray-200 mt-2">
                            <div
                              className="bg-brand-red text-xs leading-none text-center text-white"
                              style={{
                                width: `${subSection.sectionScore}%`,
                                height: "4px",
                              }}
                            ></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table-fixed min-w-full overflow-x-scroll divide-y divide-gray-200">
                  <tbody className="bg-white divide-y divide-gray-200">
                    {subSection.questions.map((question: any, z: number) => {
                      return (
                        <tr
                          className="transition-all hover:bg-gray-100 hover:shadow-lg"
                          key={i + "-" + y + "-" + z}
                        >
                          <td className="px-2 py-2 lg:px-8 lg:py-4 w-5/8 lg:w-3/6">
                            <div
                              className="flex items-top text-sm lg:text-base"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  `<p class='font-semibold'>${question.title}</p><p class='pl-4'>Comment: ${question.comment}</p>`
                                ),
                              }}
                            ></div>
                          </td>
                          <td className="px-2 py-2 lg:px-6 lg:py-4  w-2/8 lg:w-2/6">
                            <div
                              className="text-sm text-gray-900"
                              style={{ minWidth: "150px" }}
                            >
                              <strong>{question.score}%</strong>
                              <div className="shadow w-full bg-gray-200 mt-2">
                                <div
                                  className="bg-brand-red text-xs leading-none text-center text-white"
                                  style={{
                                    width: `${question.score}%`,
                                    height: "4px",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </td>
                          <td className="px-2 py-2 lg:px-6 lg:py-4  w-1/8 lg:w-1/6">
                            <div className="">
                              <button
                                className="bg-brand-red hover:bg-brand-red-dark text-white py-2 px-1 lg:px-4 focus:outline-none rounded mb-2 float-right text-sm lg:text-base"
                                onClick={() => {
                                  history.push(
                                    `/survey/${question.position}/edit`
                                  );
                                }}
                              >
                                Modify
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  });

  return (
    <div className="">
      <h1 className="pb-4"> Summary of Results</h1>
      <h3 className="font-semibold">Interpreting the Results</h3>
      <p>
        The results of this self-assessment tool should not be interpreted as
        pass or fail but rather as a mechanism to highlight which areas of the
        association have strengths and which are weaker. The results can also be
        used to define the stage of growth for the association as a whole. Five
        stages of growth for a non-governmental organization are commonly
        described as follows:
      </p>
      <table className="m-auto w-full overflow-x-scroll divide-y divide-gray-200 my-8">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="hidden md:block px-1 lg:px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              Stage
            </th>
            <th
              scope="col"
              className="px-1 lg:px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              State
            </th>
            <th
              scope="col"
              className="px-1 lg:px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              Characteristics
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
            <td className="hidden md:block px-1 lg:px-6 py-2 whitespace-nowrap">
              1.
            </td>
            <td className="px-1 lg:px-6 py-2" style={{ minWidth: "150px" }}>
              <span className="inline-flex px-2 text-xs leading-5 text-green-900 bg-yellow-100 rounded-full">
                Embryonic
              </span>
            </td>
            <td className="px-1 lg:px-6 py-2 text-base text-gray-500">
              In the initial stage a group of persons organise around a common
              purpose. The organisational structure is underdeveloped, and the
              activities are loosely structured around some ideas on what should
              be done and how resources could be mobilised. Direction is
              normally lacking and functions and roles unclear.
            </td>
          </tr>

          <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
            <td className="hidden md:block px-1 lg:px-6 py-2 whitespace-nowrap">
              2.
            </td>
            <td className="px-1 lg:px-6 py-2">
              <span className="inline-flex px-2 text-xs leading-5 text-green-900 bg-green-100 rounded-full">
                Emerging
              </span>
            </td>
            <td className="px-1 lg:px-6 py-2 text-base text-gray-500">
              The organisation still operates with volunteers but is beginning
              to put organizational systems in place. The organisation plans
              activities, outlines roles and starts following a certain
              direction.
            </td>
          </tr>

          <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
            <td className="hidden md:block px-1 lg:px-6 py-2 whitespace-nowrap">
              3.
            </td>
            <td className="px-1 lg:px-6 py-2">
              <span className="inline-flex px-2 text-xs leading-5 text-green-900 bg-blue-100 rounded-full">
                Growing
              </span>
            </td>
            <td className="px-1 lg:px-6 py-2 text-base text-gray-500">
              The organization starts rethinking service delivery in terms of
              effectiveness and efficiency, putting more sophisticated
              operational planning tools in place and attempting to attract
              donors on the basis of achievements rather than intentions. The
              organisation is more aware of its stakeholders and the possibility
              for them to influence the organisation’s policy direction and
              decision-making.
            </td>
          </tr>

          <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
            <td className="hidden md:block px-1 lg:px-6 py-2 whitespace-nowrap">
              4.
            </td>
            <td className="px-1 lg:px-6 py-2">
              <span className="inline-flex px-2 text-xs leading-5 text-green-900 bg-indigo-300 rounded-full">
                Well-Developed
              </span>
            </td>
            <td className="px-1 lg:px-6 py-2 text-base text-gray-500">
              The organization is responsive to stakeholders, activities are
              efficiently carried out and decisions are made strategically. The
              organisation is a reputable agent with stable finances consisting
              of a mix of long-term sponsors and internal resource mobilisation
              endeavours. Human resource management is oriented to motivating
              and supervising working teams of experts.
            </td>
          </tr>

          <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
            <td className="hidden md:block px-1 lg:px-6 py-2 whitespace-nowrap">
              5.
            </td>
            <td className="px-1 lg:px-6 py-2">
              <span className="inline-flex px-2 text-xs leading-5 text-green-900 bg-purple-400 rounded-full">
                Mature
              </span>
            </td>
            <td className="px-1 lg:px-6 py-2 text-base text-gray-500">
              A self-sustained organisation that can be reasonably expected to
              survive on its own.
            </td>
          </tr>
        </tbody>
      </table>
      <p className="pb-8">
        <a href="https://internationalbiosafety.org/wp-content/uploads/2020/12/KEPA-Organisational-Capacity-Assessment.pdf">
          KEPA’s “Organizational Capacity Assessment: An Introduction to a Tool”
        </a>
        .
      </p>
      <div className="py-8 mb-6">
        <button
          className={`btn btn-lg btn-success hovering ld-ext-right bg-brand-red hover:bg-brand-red-dark text-white py-2 px-4 focus:outline-none rounded mb-2 float-right ${
            isRunning ? "running" : ""
          }`}
          onClick={() => {
            setIsRunning(true);
            generatePDF();
          }}
        >
          Generate PDF of Results
          <div className="ld ld-ring ld-spin" style={{ color: "#fff" }}></div>
        </button>
      </div>
      {/*
 // @ts-ignore */}
      <div className="tabs" id="tabContainer">
        {tabs}
      </div>
      <div className="py-8">
        <button
          className={`btn btn-lg btn-success hovering ld-ext-right bg-brand-red hover:bg-brand-red-dark text-white py-2 px-4 focus:outline-none rounded mb-2 float-right ${
            isRunning ? "running" : ""
          }`}
          onClick={() => {
            setIsRunning(true);
            generatePDF();
          }}
        >
          Generate PDF of Results
          <div className="ld ld-ring ld-spin" style={{ color: "#fff" }}></div>
        </button>
      </div>
      {pdfFriendly}
    </div>
  );
};

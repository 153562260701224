import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./tailwind.output.css";

import {
  Survey,
  SurveyQuestion,
  NotFound,
  Intro,
  Summary,
} from "./sections/index";

const App = () => {
  const handleSetUser = (data: { name: string; association: string }) => {
    setUser(data);
  };

  const handleSetAnswer = (data: {
    id: string;
    score: number;
    comment: string;
    sectionId: string | undefined;
    subSectionId: string | undefined;
  }) => {
    let answerFound = false;

    if (answers.length > 0) {
      let updatedAnswers = answers.map((answer): {
        id: string;
        score: number;
        comment: string;
        sectionId: string | undefined;
        subSectionId: string | undefined;
      } => {
        if (answer.id === data.id) {
          answerFound = true;
          return {
            ...answer,
            score: data.score,
            comment: data.comment,
            sectionId: data.sectionId,
            subSectionId: data.subSectionId,
          }; //gets everything that was already in item, and updates "done"
        }
        return answer; // else return unmodified item
      });
      if (answerFound) {
        setAnswers(updatedAnswers);
      } else {
        setAnswers([...answers, { ...data }]);
      }
    } else {
      setAnswers([...answers, { ...data }]);
    }
  };

  let initialUser = [];
  const localStorageUser = localStorage.getItem("userDetails");
  if (localStorageUser) {
    initialUser = JSON.parse(localStorageUser);
  }

  let initialAnswers = [];
  const localStorageAnswers = localStorage.getItem("answers");
  if (localStorageAnswers) {
    initialAnswers = JSON.parse(localStorageAnswers);
  }

  const [user, setUser] = useState<any>(initialUser);
  const [answers, setAnswers] = useState<any[]>(initialAnswers);

  useEffect(() => {
    window.localStorage.setItem("answers", JSON.stringify(answers));
    window.localStorage.setItem("userDetails", JSON.stringify(user));
  });

  return (
    <Router>
      <div className="App">
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center md:py-12">
          <div className="relative py-3 md:w-2/3 sm:mx-auto">
            <div className="relative px-4 py-10 bg-white shadow-lg sm:p-8 card">
              <div className="max-w-2/3 mx-auto">
                <div className="divide-y divide-gray-200">
                  <div className="py-4 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                    <Switch>
                      <Route exact path="/" component={Intro} />
                      <Route
                        exact
                        path="/survey"
                        component={() => (
                          <Survey
                            setUserCallback={handleSetUser}
                            existingUser={user}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/survey/:id"
                        component={() => (
                          <SurveyQuestion
                            user={user}
                            setAnswerCallback={handleSetAnswer}
                            answers={answers}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/survey/:id/edit"
                        component={() => (
                          <SurveyQuestion
                            user={user}
                            setAnswerCallback={handleSetAnswer}
                            answers={answers}
                            editing={true}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/summary"
                        component={() => (
                          <Summary answers={answers} user={user} />
                        )}
                      />
                      <Route component={NotFound} />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

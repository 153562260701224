import React from "react";

export const NotFound = () => {
  return (
    <div className="">
      <h1>Oops!</h1>
      <p>Page not found.</p>
    </div>
  );
};

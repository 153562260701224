import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { useHistory } from "react-router-dom";

interface Props {
  id: string;
  title: string;
  sectionHeader: string;
  sectionId: string | undefined;
  subSectionId: string | undefined;
  editing?: boolean;
  existingAnswer: { id: string; score: number; comment: string } | undefined;
  nextCallback: (data: object) => void;
  prevCallback: () => void;
  setAnswerCallback: (data: {
    id: string;
    score: number;
    comment: string;
    sectionId: string | undefined;
    subSectionId: string | undefined;
  }) => void;
}

export const Question = ({
  id,
  title,
  sectionId,
  subSectionId,
  sectionHeader,
  existingAnswer,
  editing,
  nextCallback,
  prevCallback,
  setAnswerCallback,
}: Props) => {
  const [rangeValue, setRangeValue] = useState(0);
  const [comments, setComments] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (existingAnswer) {
      setRangeValue(existingAnswer.score);
      setComments(existingAnswer.comment);
    }
  }, [existingAnswer]);

  const handleNext = () => {
    setAnswerCallback({
      id: id,
      score: rangeValue,
      comment: comments,
      sectionId,
      subSectionId,
    });
    nextCallback({ rangeValue, comment: comments });
  };
  const handlePrev = () => {
    setAnswerCallback({
      id: id,
      score: rangeValue,
      comment: comments,
      sectionId,
      subSectionId,
    });
    prevCallback();
  };

  let buttons;

  if (editing) {
    buttons = (
      <React.Fragment>
        <button
          className="bg-brand-red hover:bg-brand-red-dark text-white py-2 px-4 focus:outline-none rounded mb-2 float-right"
          onClick={() => {
            setAnswerCallback({
              id: id,
              score: rangeValue,
              comment: comments,
              sectionId,
              subSectionId,
            });
            history.push(`/summary`);
          }}
        >
          Return to Summary
        </button>
      </React.Fragment>
    );
  } else {
    buttons = (
      <React.Fragment>
        <div className="flex-1">
          <button
            className="bg-brand-red hover:bg-brand-red-dark text-white py-2 px-4 focus:outline-none rounded mb-2 "
            onClick={handlePrev}
          >
            Previous
          </button>
        </div>
        <button
          className="bg-brand-red hover:bg-brand-red-dark text-white py-2 px-4 focus:outline-none rounded mb-2 float-right"
          onClick={handleNext}
        >
          Next
        </button>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="mb-8 title-bar">
        <h1
          className="pb-4"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(sectionHeader),
          }}
        ></h1>
      </div>
      <p
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
        className="text-xl"
      ></p>
      <div className="flex  w-3/4 m-auto items-center h-32 justify-center">
        <div className="py-1 relative min-w-full">
          <div className="text-center font-semibold text-2xl">
            {rangeValue}%
          </div>
          <div className="">
            <input
              className="rounded-lg overflow-hidden appearance-none bg-gray-400 h-4 w-full focus:outline-none"
              type="range"
              min="0"
              max="100"
              step="1"
              value={rangeValue}
              onChange={(event) => setRangeValue(parseInt(event.target.value))}
            />
            <div className="absolute text-gray-800 -ml-1 bottom-0 left-0 -mb-6 text-sm">
              Meets no requirements
            </div>
            <div className="absolute text-gray-800 -mr-1 bottom-0 right-0 -mb-6 text-sm">
              Meets all requirements
            </div>
          </div>
        </div>
      </div>

      <label className="block pb-6 pt-6">
        <span className="text-gray-700 font-semibold">Comments</span>
        <textarea
          className="form-textarea mt-1 block w-full"
          placeholder="Enter your comments."
          rows={4}
          value={comments}
          onChange={(event) => setComments(event.target.value)}
        ></textarea>
      </label>
      <div className="border-b rounded-tl-lg rounded-tr-lg p-2 flex pb-6">
        {buttons}
      </div>
    </React.Fragment>
  );
};
